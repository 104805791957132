<template>
  <div
    class="popup-wrapper"
    :class="currentPopup.props.popupClass"
    v-if="currentPopup.isShown"
  >
    <div class="modal-box">
      <button
        class="carousel__button is-close"
        :aria-label="'close'"
        @click="closePopup"
      >
        <Icon type="close" />
      </button>
      <component v-if="currentPopup.name" v-bind:is="currentPopup.name" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import PopupAvatarTerms from "@/components/Popups/PopupAvatarTerms";
import PopupCropper from "@/components/Popups/PopupCropper";
import PopupProfileCongrats from "@/components/Popups/PopupProfileCongrats";
import PopupViewCertificate from "@/components/Popups/PopupViewCertificate";
import PopupDeleteAchievement from "@/components/Popups/PopupDeleteAchievement";
import PopupRevokeApplication from "@/components/Popups/PopupRevokeApplication";
import PopupMembershipSuccess from "@/components/Popups/PopupMembershipSuccess";
import PopupCookie from "@/components/Popups/PopupCookie";
import PopupMustEdit from "@/components/Popups/PopupMustEdit";
import PopupAuthInfo from "@/components/Popups/PopupAuthInfo.vue";
import PopupSendStatement from "@/components/Popups/PopupSendStatement.vue";

export default {
  name: "PopupController",
  components: {
    PopupSendStatement,
    PopupAuthInfo,
    PopupMustEdit,
    PopupCookie,
    PopupMembershipSuccess,
    PopupRevokeApplication,
    PopupDeleteAchievement,
    PopupViewCertificate,
    PopupProfileCongrats,
    PopupCropper,
    PopupAvatarTerms,
    Icon,
  },
  computed: {
    ...mapState(["currentPopup"]),
  },
  watch: {
    "$store.state.currentPopup.isShown": function () {
      this.$store.state.currentPopup.isShown
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", { isShown: false });
    },
    closePopupEsc(e) {
      if (e.key === "Escape") this.closePopup();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.closePopupEsc);
  },
  unmounted() {
    window.removeEventListener("keydown", this.closePopupEsc);
  },
};
</script>

<style scoped></style>
