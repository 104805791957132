import axios from "axios";
import store from "@/store";
import router from "@/router";

const backend = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api/v1`,
  headers: {
    Locale: "ru",
  },
});

backend.interceptors.request.use(
  (config) => {
    config.headers.common = {
      ...config.headers.common,
      Locale: store.state.uiLanguage,
      Authorization: `Bearer ${store.state.user.backendToken}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.commit("user/killUser");
      store.commit("user/killUserFull");
      store.dispatch("logout");
    }
    if (error.response?.status === 403) router.push({ name: "403" });

    if (error.response?.status === 500)
      store.commit("pushToTray", {
        text: "Высокая нагрузка на сервер. Перезагрузите страницу.",
        type: "error",
      });

    if (error.response?.status === 429)
      store.commit("pushToTray", {
        text: "Высокая нагрузка на сервер. Попробуйте через 1 минуту",
        type: "error",
      });

    throw error;
  }
);

export default backend;
