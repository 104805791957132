export default {
  POSTCodeRequest: {
    url: "/code",
    type: "post",
    jsonKeys: ["login", "captcha", "captcha_secret", "type_code"],
  },
  POSTSignUp: {
    url: "/signup",
    type: "post",
    jsonKeys: ["email", "code"],
  },
  POSTSignIn: {
    url: "/signin",
    type: "post",
    jsonKeys: ["email", "code", "type"],
  },
  POSTSendMembership: {
    url: "/client/liga-status/send-statement",
    type: "post",
    jsonKeys: ["liga_status_need"],
  },
  GETCaptcha: {
    url: "/captcha",
    type: "get",
  },
  POSTSaveInfo: {
    url: "/client/info/save",
    type: "post",
    jsonKeys: [
      "avatar",
      "last_name",
      "first_name",
      "middle_name",
      "birthday",
      "gender",
      "education_city_id",
      "education_region_id",
      "food_type",
      "clothing_size",
      "health_features",
      "phone",
      "is_citizen_russia",
      "educational_establishment_id",
      "faculty_id",
      "speciality_education",
      "group",
      "year_ending_education",
      "level_education",
      "socials",
      "socials[vk][href]",
      "socials[vk][type]",
      "about",
      "course_level",
    ],
    formDataWrapper: true,
  },
  POSTSaveCitizen: {
    url: "/client/info/save",
    type: "post",
    jsonKeys: ["is_citizen_russia"],
  },
  POSTSavePassport: {
    url: "/client/info/save-passport",
    type: "post",
    jsonKeys: [
      "series",
      "number",
      "issue_at",
      "who_issued_document",
      "division_code",
      "place_birth",
      "place_registration",
      "place_residence",
      "inn",
      "citizenship",
      "series_number",
      "migration_card_series",
      "migration_card_number",
      "migration_card_issue_at",
      "migration_card_expiration_at",
      "registration_list_place_at",
      "registration_list_until_at",
      "tin",
      "place_issue",
      "document_foreign_passport_main_page",
      "document_foreign_passport_registration_page",
      "document_foreign_passport_translate",
      "document_migration_card_front",
      "document_migration_card_back",
      "document_registration_sheet_front",
      "document_registration_sheet_back",
      "document_passport_main_page",
      "document_passport_register_page",
      "document_passport_old_version_page",
      "document_inn",
      "document_tin",
    ],
    formDataWrapper: true,
  },
  POSTSaveEducation: {
    url: "/client/info/save-education",
    type: "post",
    jsonKeys: [
      "document_student_record_book_main_page",
      "document_student_record_book_session_pages_one",
      "document_student_record_book_session_pages_two",
      "document_student_record_book_session_pages_three",
      "document_student_record_book_session_pages_four",
      "document_student_record_book_session_pages_five",
      "document_reference",
      "document_higher_education",
      "document_student_billet",
      "document_social_need",
      "document_certificate_absence_academic_debt",
      "document_certificate_guardianship_adoption",
      "document_reference_income_parent_one",
      "document_reference_income_parent_two",
      "document_certificate_family_composition",
      "document_reference_scholarship_amount",
      "document_certificate_personal_income_student_last_3_months",
      "document_consent_parents",
    ],
    formDataWrapper: true,
  },
  POSTOneDocument: {
    url: "/client/info/update-document",
    type: "post",
    jsonKeys: ["key_document", "file_document"],
    formDataWrapper: true,
  },
  POSTCreateAppeal: {
    url: "/client/support/send",
    type: "post",
    jsonKeys: [
      "category_id",
      "name",
      "message",
      "is_anonymous",
      "files[0]",
      "files[1]",
      "files[2]",
    ],
    formDataWrapper: true,
  },
  POSTSendToChat: {
    url: "/client/support/:{id}/send-message",
    type: "post",
    routerKeys: ["id"],
    jsonKeys: ["message", "is_anonymous", "files[0]", "files[1]"],
    formDataWrapper: true,
  },
  GETMyAppeals: {
    url: "/client/support/list",
    type: "get",
  },
  GETInfo: {
    url: "/client/info",
    type: "get",
  },
  GETSystemInfo: {
    url: "/config/info",
    type: "get",
  },
  GETSProjectsList: {
    url: "/projects/all",
    type: "get",
  },
  GETTelegramUnlink: {
    url: "/security/telegram-unlink",
    type: "get",
  },
  GETPortfolioList: {
    url: "/client/portfolio/list",
    type: "get",
  },
  POSTSavePortfolio: {
    url: "/client/portfolio/save-portfolio",
    type: "post",
    jsonKeys: ["image", "title", "year"],
    formDataWrapper: true,
  },
  DELPortfolioItem: {
    url: "/client/portfolio/:{id}/delete",
    type: "delete",
    routerKeys: ["id"],
  },

  POSTCreateStatement: {
    url: "/client/projects/:{id}/create-statement",
    type: "post",
    jsonKeys: ["category_id"],
    routerKeys: ["id"],
    formDataWrapper: true,
  },
  POSTSendStatement: {
    url: "/client/statement/:{id}/send",
    type: "post",
    jsonKeys: [
      // "video_card",
      "community_represent",
      "community_represent_other",
      "community_represent_name",
      "position_organization",
      "position_organization_other",
      "what_does_student_mean_you",
      "purpose_travel",
      "your_main_achievements",
      "hear_about_forum",
      "hear_about_forum_other",
      "first_direction_id",
      "second_direction_id",
      "third_direction_id",
      "first_team",
      "second_team",
      "third_team",
    ],
    routerKeys: ["id"],
    formDataWrapper: true,
  },
  GETStatementList: {
    url: "/client/statement/list",
    type: "get",
  },
  POSTAddDocument: {
    url: "/client/statement/:{id}/add-document",
    type: "post",
    jsonKeys: [
      "media_id",
      "project_tree_id",
      "other[project_name]",
      "other[project_year]",
      "other[project_maintainer]",
      "other[project_maintainer_phone]",
      "key",
    ],
    routerKeys: ["id"],
    formDataWrapper: true,
  },
  POSTSaveDocument: {
    url: "/client/portfolio/save-document",
    type: "post",
    jsonKeys: ["file"],
    formDataWrapper: true,
  },
  POSTRollbackStatement: {
    url: "/client/statement/:{id}/rollback",
    type: "post",
    routerKeys: ["id"],
    formDataWrapper: true,
  },
  DELStatementDocument: {
    url: "/client/statement/:{id}/delete-document",
    type: "post",
    jsonKeys: ["media_id"],
    routerKeys: ["id"],
  },
  POSTSaveSocialActivity: {
    url: "/client/info/save-social-activity",
    type: "post",
    jsonKeys: ["social_activity"],
  },
  POSTSaveLaborActivity: {
    url: "/client/info/save-labor-activity",
    type: "post",
    jsonKeys: ["labor_activity"],
  },
};
