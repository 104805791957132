<template>
  <div class="popup-avatar-terms">
    <div class="popup-avatar-terms__title">Загрузить аватар</div>
    <div class="popup-avatar-terms__info">
      Вы можете загрузить изображение в формате JPG, не более 2 МБ. Если у Вас
      возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего
      размера.
    </div>
    <ol class="popup-avatar-terms__rules">
      <li>
        Изображение должно быть четкое, положение фотографируемого в анфас;
      </li>
      <li>
        Лицо фотографируемого должно занимать не менее трети свободной площади
        фотографии;
      </li>
      <li>
        Фотографируемый должен быть равномерно освещен для получения отличной
        детализации лица;
      </li>
      <li>Выражение лица спокойное;</li>
      <li>
        Взгляд направлен прямо (в объектив фотокамеры), глаза не должны быть
        закрыты или прищурены;
      </li>
      <li>
        Если человек обычно носит очки, то он должен быть в очках и когда его
        фотографируют. Очки должны с чистыми и прозрачными стеклами, чтобы
        зрачки и радужные оболочки ясно видимы. Оправа очков не должна закрывать
        глаза. Очки не должны служить источником бликов на фотографии;
      </li>
      <li>Изображение на фотографии – цветное;</li>
      <li>Фон фотографии должен быть одноцветным, без какой-либо текстуры;</li>
      <li>
        Количество человек, изображенных на фотографии, не должно быть более
        одного. На фотографии не должны присутствовать иные предметы.
      </li>
    </ol>
    <div class="popup-avatar-terms__btn">
      <InputFile class="file-avatar" :cropper-type="'avatar'">
        Загрузить фотографию
      </InputFile>
      <Button @click="closePopup" secondary>Отмена</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import InputFile from "@/components/Blocks/InputFile";
export default {
  name: "PopupAvatarTerms",
  components: { InputFile, Button },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
  },
};
</script>

<style lang="scss">
.popup-avatar-terms {
  &__title {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 12px;
    @include adaptive(phone) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__info {
    margin-bottom: 12px;
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  ol.popup-avatar-terms__rules {
    list-style: number;
    margin-left: 25px;
    margin-bottom: 24px;
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &__btn {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    @include adaptive(phone) {
      flex-direction: column;
      & button {
        align-self: center;
      }
    }
  }
}
</style>
