export default () => ({
  user: {
    about: null,
    avatar: null,
    bank_account_number: null,
    birthday: null,
    birthday_format: null,
    created_at: "",
    education: {
      document_files: {
        document_consent_parents: {
          full: null,
          thumb: null,
        },
        document_higher_education: {
          full: null,
          thumb: null,
        },
        document_reference: {
          full: null,
          thumb: null,
        },
        document_social_need: {
          full: null,
          thumb: null,
        },
        document_student_billet: {
          full: null,
          thumb: null,
        },
        document_student_record_book_main_page: {
          full: null,
          thumb: null,
        },
        document_student_record_book_session_pages_five: {
          full: null,
          thumb: null,
        },
        document_student_record_book_session_pages_four: {
          full: null,
          thumb: null,
        },
        document_student_record_book_session_pages_one: {
          full: null,
          thumb: null,
        },
        document_student_record_book_session_pages_three: {
          full: null,
          thumb: null,
        },
        document_student_record_book_session_pages_two: {
          full: null,
          thumb: null,
        },
        document_certificate_absence_academic_debt: {
          full: null,
          thumb: null,
        },
      },
    },
    social_documents: {
      document_files: {
        document_certificate_guardianship_adoption: {
          full: null,
          thumb: null,
        },
        document_reference_income_parent_one: {
          full: null,
          thumb: null,
        },
        document_reference_income_parent_two: {
          full: null,
          thumb: null,
        },
        document_certificate_family_composition: {
          full: null,
          thumb: null,
        },
        document_reference_scholarship_amount: {
          full: null,
          thumb: null,
        },
        document_certificate_personal_income_student_last_3_months: {
          full: null,
          thumb: null,
        },
      },
    },
    email: "",
    fa2: "",
    fake_id: null,
    fio: "",
    first_name: null,
    gender: null,
    group: null,
    id: null,
    info_course_level: {
      id: null,
      title: "",
    },
    education_city_id: null,
    education_region_id: null,
    info_education_city: {},
    info_educational_establishment: null,
    info_faculty: null,
    info_fix: null,
    info_level_education: {
      id: null,
      title: "",
    },
    info_liga_status: {
      id: 0,
      is_member_liga: false,
      title: "Нет статуса лиги",
    },
    info_statements: {
      data: [],
      status: true,
    },
    info_social_activity: {
      data: [],
      status: true,
    },
    info_labor_activity: {
      data: [],
      status: true,
    },
    is_citizen_russia: true,
    is_link_telegram: true,
    is_terms_confirm: true,
    last_name: null,
    locale: "ru",
    middle_name: null,
    passport: {
      series: "",
      number: "",
      issue_at: "",
      who_issued_document: "",
      division_code: "",
      place_birth: "",
      place_registration: "",
      place_residence: "",
      inn: "",
      citizenship: "",
      series_number: "",
      migration_card_series: "",
      migration_card_number: "",
      migration_card_issue_at: "",
      migration_card_expiration_at: "",
      registration_list_place_at: "",
      registration_list_until_at: "",
      tin: "",
      place_issue: "",
      document_files: {
        document_foreign_passport_main_page: {
          full: null,
          thumb: null,
        },
        document_foreign_passport_registration_page: {
          full: null,
          thumb: null,
        },
        document_foreign_passport_translate: {
          full: null,
          thumb: null,
        },
        document_migration_card_front: {
          full: null,
          thumb: null,
        },
        document_migration_card_back: {
          full: null,
          thumb: null,
        },
        document_registration_sheet_front: {
          full: null,
          thumb: null,
        },
        document_registration_sheet_back: {
          full: null,
          thumb: null,
        },
        document_tin: {
          full: null,
          thumb: null,
        },
        document_passport_main_page: {
          full: null,
          thumb: null,
        },
        document_passport_register_page: {
          full: null,
          thumb: null,
        },
        document_passport_old_version_page: {
          full: null,
          thumb: null,
        },
        document_inn: {
          full: null,
          thumb: null,
        },
        document_passport_6_7_page: {
          full: null,
          thumb: null,
        },
      },
    },
    socials: {
      data: [],
      status: true,
    },
    speciality_education: "",
    telegram: "",
    telegram_link: "",
    year_ending_education: "",
    href_vk_publication_project: "",
    is_not_student: "",
    info_validation_documents: { data: [] },
    food_type: "",
    clothing_size: "",
    health_features: "",
  },
  backendToken: "",
  isAuthenticated: false,
  isInfoLoading: false,
  currentMembershipStatus: 0,
  isTelegram: null,
  isTelegramInfo: false,
  isSPO: false,
  isRT: true,
  disabledFields: {
    // profile
    isProfileFilled: false,
    isEducationFilled: false,
    isSocialsFilled: false,
    //transport grant
    isProjectProfileFilled: false,
    isProjectPersonalFilled: false,
    isProjectEducationFilled: false,
    //student year profile
    isSYPersonalFilled: false,
    //nominations
    isIndividualNominationFilled: false,
    isCollectiveNominationFilled: false,
    isGranprixNominationFilled: false,
    // student year category education
    isIndividualEducationFilled: false,
    isCollectiveEducationFilled: false,
    isGranprixEducationFilled: false,
    // student year data for collective category
    isCollectiveDataFilled: false,
    //achievement year profile
    isAYPersonalFilled: false,
    //nominations
    isAYIndividualNominationFilled: false,
    isAYCollectiveNominationFilled: false,
    isAYGranprixNominationFilled: false,
    // achievement year category education
    isAYIndividualEducationFilled: false,
    isAYCollectiveEducationFilled: false,
    isAYGranprixEducationFilled: false,
    // achievement year data for collective category
    isAYCollectiveDataFilled: false,
    // isCitizenRussia
    isCitizenRussia: true,
    //HousingStock
    isHSProfileFilled: false,
    isHSPersonalDataFilled: false,
    //LigaForum tracks
    isLFFirstShiftTracksFilled: false,
    isLFSecondShiftTracksFilled: false,
    isLFThirdShiftTracksFilled: false,
    //LigaForum profile
    isLFFirstShiftProfileFilled: false,
    isLFSecondShiftProfileFilled: false,
    isLFThirdShiftProfileFilled: false,
    //activity
    isSocialActivityFilled: false,
    isLaborActivityFilled: false,
    //student or not
    isNotStudent: false,
  },
  isEducationPopupShown: true,
  setIsProfileMustEdit: false,
  setIsPassportMustEdit: false,
  socialActivity: [],
  laborActivity: [],
});
