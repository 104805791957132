import layoutsList from "@/enums/layoutsList";
import layoutsFileNameList from "@/enums/layoutsFileNameList";

export async function loadLayoutMiddleware(route) {
  const { layout } = route.meta;
  const normalizedLayoutName = layout || layoutsList.default;
  const fileName = layoutsFileNameList[normalizedLayoutName];
  const fileNameWithoutExtension = fileName.split(".vue")[0];

  const component = await import(`@/layouts/${fileNameWithoutExtension}.vue`);
  route.meta.layoutComponent = component.default;
}
