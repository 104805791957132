<template>
  <div>
    <AppLayout>
      <router-view />
      <transition name="popup-anim"> <PopupController /> </transition>
    </AppLayout>
  </div>
  <PopupController />
  <div class="alert-body">
    <transition-group name="tray-anim">
      <Alert v-for="item in trayItems" :key="item.id" :label="item" />
    </transition-group>
  </div>
</template>

<script>
import Layout from "@/layouts/Layout.vue";
import LayoutStart from "@/layouts/LayoutStart.vue";
import LayoutNoSidebar from "@/layouts/LayoutNoSidebar.vue";
import PopupController from "@/components/Popups/PopupController";
import Alert from "@/components/Blocks/Alert";
import { mapState } from "vuex";
import AppLayout from "@/layouts/AppLayout.vue";

export default {
  name: "App",
  components: {
    AppLayout,
    Alert,
    Layout,
    PopupController,
    LayoutStart,
    LayoutNoSidebar,
  },

  computed: {
    ...mapState(["trayItems"]),
  },
};
</script>

<style lang="scss">
@import "~@/scss/style.scss";
</style>
