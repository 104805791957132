<template>
  <div class="popup-profile-congrats">
    <div class="popup-profile-congrats__title">Поздравляем!</div>
    <div class="popup-profile-congrats__text">
      Вы заполнили основную часть данных о себе, теперь Вам доступны заполненные
      разделы "Мое портфолио", "Мои заявки", "Мои обращения". Вы можете
      заполнить раздел "Персональные данные" сейчас или вернуться позже.
    </div>
    <div class="popup-profile-congrats__btn">
      <Button @click="goNext"> Продолжить заполнение </Button>
      <Button secondary @click="goEvents"> Вернуться позже </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "PopupProfileCongrats",
  components: { Button },
  methods: {
    goEvents() {
      this.$store
        .dispatch("user/hasProfileData", [
          [
            "info_educational_establishment",
            "info_faculty",
            "info_level_education",
          ],
          "isEducationFilled",
        ])
        .then(() => {
          this.$router.push({ name: "Events" });
          this.$store.commit("user/setEducationPopupShown");
        });
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    goNext() {
      this.$store
        .dispatch("user/hasProfileData", [
          [
            "info_educational_establishment",
            "info_faculty",
            "info_level_education",
          ],
          "isEducationFilled",
        ])
        .then(() => {
          this.$router.push({ name: "PersonalData" });
          this.$store.commit("user/setEducationPopupShown");
        });
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
  },
};
</script>

<style lang="scss">
.popup-profile-congrats {
  &__title {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 12px;
    @include adaptive(phone) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__text {
    margin-bottom: 16px;
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
    @include adaptive(phone) {
      flex-direction: column;
      & button {
        align-self: center;
      }
    }
  }
}
</style>
