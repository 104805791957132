<template>
  <div class="layout">
    <Header :is-info-loading="isInfoLoading" :avatar="info?.avatar" />
    <main class="main">
      <div class="main__wrapper">
        <Sidebar
          :avatar="info?.avatar"
          member="true"
          :is-info-loading="isInfoLoading"
        />
        <div
          class="slot__wrapper"
          :class="{ 'no-overflow': $route.name === 'Events' }"
        >
          <slot />
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/LayoutSection/Header.vue";
import Sidebar from "@/components/LayoutSection/Sidebar.vue";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
import Footer from "@/components/LayoutSection/Footer.vue";
export default {
  name: "Layout",
  components: { Footer, Sidebar, Header },
  props: ["info", "isInfoLoading"],
};
</script>

<style lang="scss">
.layout {
  min-height: 100vh;
  color: var(--text--color);
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-weight: 400;
  position: relative;
}
.main {
  max-width: 1560px;
  margin: 0 auto;
  padding: 80px 20px;
  box-sizing: content-box;
  &__wrapper {
    display: flex;
    gap: 40px;
  }
  .slot__wrapper {
    width: 100%;
    overflow: hidden;
    @include scrollbar();
  }
  .no-overflow {
    overflow: unset;
  }
  @include adaptive(tablet-big) {
    padding: 20px;
  }
  @include adaptive(tablet-small) {
    .slot__wrapper {
      overflow-x: clip;
      overflow-y: unset;
    }
  }
}
</style>
