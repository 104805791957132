<template>
  <div class="popup-must-edit">
    <div class="popup-must-edit__text">
      Программа обнаружила ошибки в Ваших личных данных. <br />
      Есть предложенные изменения на {{ pagesMustEdit }}. <br />
      Если Вы не согласны, обращайтесь в личные сообщения ВКонтакте
      <a href="https://vk.com/liga_studentov" target="_blank">
        https://vk.com/liga_studentov
      </a>
    </div>
    <router-link :to="{ name: currentLink }" @click="closePopup">
      <Button>Перейти</Button>
    </router-link>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { mapState } from "vuex";
export default {
  name: "PopupMustEdit",
  components: { Button },
  computed: {
    currentLink() {
      if (this.setIsProfileMustEdit) {
        return "ProfileData";
      }
      if (this.setIsPassportMustEdit) {
        return "PersonalData";
      }
      return "";
    },
    pagesMustEdit() {
      if (this.setIsProfileMustEdit) {
        return 'странице: "Мои данные"';
      }
      if (this.setIsPassportMustEdit) {
        return 'странице: "Персональные данные"';
      }
      if (this.setIsPassportMustEdit && this.setIsProfileMustEdit) {
        return 'страницах: "Мои данные" и "Персональные данные"';
      }
      return "";
    },
    ...mapState("user", ["setIsProfileMustEdit", "setIsPassportMustEdit"]),
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupMustEdit",
        isShown: false,
      });
    },
  },
};
</script>

<style lang="scss">
.popup-must-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  align-items: center;
  &__text {
    font-weight: 500;
    text-align: center;
    line-height: 22px;
  }
}
</style>
