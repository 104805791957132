<template>
  <div class="popup-membership-success">
    <div class="popup-membership-success__header">
      Вы успешно отправили заявку на членство
    </div>
    <div class="popup-membership-success__text">
      Отследить свой статус заявки вы можете в разделе
      <router-link @click="closePopup" :to="{ name: 'Applications' }">
        «Мои заявки»
      </router-link>
    </div>
    <Button class="popup-membership-success__btn" @click="closePopup">
      Закрыть
    </Button>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "PopupMembershipSuccess",
  components: { Button },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
  },
};
</script>

<style lang="scss">
.popup-membership-success {
  &__header {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    @include link();
    margin-bottom: 15px;
    a {
      white-space: nowrap;
    }
  }

  @include adaptive(phone) {
    &__header {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
    &__btn {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
