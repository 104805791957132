<template>
  <button class="btn" :class="className" @click="handleClick">
    <Icon class="btn__icon" v-if="iconType" :type="iconType" />
    <span class="btn__slot">
      <slot></slot>
    </span>
    <div class="btn__preloader">
      <div class="btn__anim"></div>
    </div>
  </button>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Button",
  components: { Icon },
  props: {
    profile: Boolean,
    secondary: Boolean,
    large: Boolean,
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    green: Boolean,
    cropper: Boolean,
    iconType: String,
  },
  computed: {
    className() {
      return {
        "btn--profile": this.profile,
        "btn--secondary": this.secondary,
        "btn--large": this.large,
        "btn--small": this.small,
        "btn--disabled": this.disabled,
        "btn--loading": this.loading,
        "btn--cropper": this.cropper,
        "btn--green": this.green,
      };
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) this.$emit("protected-click");
    },
  },
};
</script>

<style lang="scss">
.btn {
  display: inline-block;
  text-align: center;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  background-color: var(--background--btn--red);
  border: 1px solid var(--background--btn--red);
  color: var(--white);

  &__icon {
    font-size: 20px;
  }
  &:hover {
    background-color: var(--background--btn--red--hover);
  }
  &--cropper {
    display: inline-flex;
    gap: 10px;
    background: var(--white);
    padding: 8px 10px;
    color: var(--text--color);
    border-radius: 8px;
    border: 1px solid var(--text--color);
    font-weight: 400;
    line-height: 2.2rem;
    align-items: center;
    &:hover {
      background: inherit;
    }
    //@include adaptive(phone) {
    //  padding: 6px 12px;
    //  font-size: 1.2rem;
    //}
  }
  &--secondary {
    display: inline-block;
    border: 1px solid var(--background--btn--red);
    background-color: var(--background--btn--secondary);
    color: var(--background--btn--red);
    //@include adaptive(phone) {
    //  padding: 6px 12px;
    //  font-size: 1.2rem;
    //}
    &:hover {
      color: var(--background--btn--red--hover);
      border: 1px solid var(--background--btn--red--hover);
      background: var(--background--btn--secondary--hover);
    }
  }
  &--profile {
    background: var(--background--btn--profile);
    border: 1px solid var(--white);
    padding: 16px 48px;
    //@include adaptive(phone) {
    //  padding: 6px 12px;
    //  font-size: 1.2rem;
    //}
    &:hover {
      background: var(--background--btn--profile--hover);
      border: 1px solid var(--background--btn--profile);
      color: var(--background--btn--profile);
    }
    &:active {
      background: var(--white);
      border: 1px solid var(--background--main);
    }
  }
  &--large {
    padding: 16px 24px;
    font-size: 1.6rem;
  }
  &--small {
    padding: 6px 12px;
    font-size: 1.2rem;
  }
  &--disabled {
    pointer-events: none;
    background-color: var(--background--disabled);
    border-color: var(--background--disabled);
    color: var(--color--disabled);
    &.btn--secondary {
      background: var(--background--btn--secondary--disabled);
      border: 1px solid var(--background--disabled);
    }
    &.btn--profile {
      color: var(--color--disabled);
      background-color: var(--white);
      border: 1px solid var(--color--disabled);
    }
  }
  &--loading {
    position: relative;
    overflow: hidden;
    border-color: transparent;
    pointer-events: none;
    &.btn--profile {
      opacity: 0.9;
    }
    .btn__preloader {
      display: grid;
    }
  }
  &--green {
    background-color: #10b981;
    border: 1px solid #10b981;
    color: var(--white);
    &:hover {
      background-color: #069968;
    }
  }
  &__preloader {
    display: none;
    background-color: rgba(0, 0, 0, 0.1);
    @include full-absolute();
  }
  &__slot {
    white-space: nowrap;
    a {
      color: inherit;
    }
  }
  &__anim {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: auto;
    position: relative;
    transform: translateZ(0);
    color: var(--color--disabled);
    animation: btn-preloader 1.5s ease infinite;
  }
}
</style>
