<template>
  <div class="popup-auth-info__wrapper">
    <Icon @click="closePopup" class="popup-auth-info__close" type="Close" />
    <div class="popup-auth-info__content">
      <ol>
        <li>
          Если Ваш аккаунт привязан к Telegram-боту
          <a href="https://t.me/LigaStudentovBot" target="_blank">
            https://t.me/LigaStudentovBot
          </a>
          и код в него не приходит - проверьте, не блокировали ли Вы его. Если
          блокировали - нажмите в боте “Старт” и заново запросите код.
        </li>
        <li>
          Если Telegram-бот активен, но код всё равно не приходит, убедитесь
          внутри Telegram-бота
          <a href="https://t.me/LigaStudentovBot" target="_blank">
            https://t.me/LigaStudentovBot
          </a>
          к какому Email он привязан. Telegram-бот > Меню > Мой аккаунт. Если
          Email указан неверный - пишите неверный и новый Email в Telegram
          <a href="https://t.me/Paulish" target="_blank">
            https://t.me/Paulish
          </a>
        </li>
        <li>
          Если вы отвязали аккаунт от Telegram-бота и код не приходит на Email,
          убедитесь в правильности написания Email, проврьте Telegram-бота
          <a href="https://t.me/LigaStudentovBot" target="_blank">
            https://t.me/LigaStudentovBot
          </a>
          и если ничего не помогло, тогда пишите в Telegram
          <a href="https://t.me/Paulish" target="_blank">
            https://t.me/Paulish
          </a>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon.vue";

export default {
  components: { Icon },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", { isShown: false });
    },
  },
};
</script>

<style lang="scss">
.popup-auth-info {
  .modal-box {
    position: relative;
  }
  &__close {
    position: absolute;
    font-size: 15px;
    color: var(--input--placeholder);
    cursor: pointer;
    right: 15px;
    top: 15px;
    &:hover {
      -webkit-text-stroke-width: 0.05rem;
    }
  }
  &__content {
    ol {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      padding-left: 5px;
    }
    a {
      color: #4f46e5;
      &:hover {
        -webkit-text-stroke-width: 0.03rem;
      }
    }
  }
  @include adaptive(phone) {
    &__wrapper {
      font-size: 14px;
    }
  }
}
</style>
