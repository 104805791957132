import axios from "axios";
import store from "@/store";
import moment from "moment";

export async function getImg(url) {
  let data = "";
  await axios({
    method: "get",
    url: `${process.env.VUE_APP_BACKEND_URL}${url}`,
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${store.state.user.backendToken}`,
    },
  }).then((response) => {
    return (data = Buffer.from(response.data, "binary").toString("base64"));
  });
  return data;
}
export async function getPDF(url, label) {
  await axios({
    method: "get",
    url: `${process.env.VUE_APP_BACKEND_URL}${url}`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${store.state.user.backendToken}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", label);
    document.body.appendChild(link);
    link.click();
  });
}

export function allPeriodWorking(array) {
  let startDate;
  let endDate;
  if (array) {
    startDate = array[0]?.start_at_format;
    endDate = array[array.length - 1]?.end_at_format;
  }
  return `${startDate} - ${endDate}`;
}
export function currentYearCalc(pastYear = 0) {
  return `${moment()
    .subtract(pastYear + 1, "years")
    .format("YY")}/${moment().subtract(pastYear, "years").format("YY")}`;
}
export function checkTrueEmails(email) {
  let invalidEmails = [
    "@mil.ru",
    "@naik.ru",
    "@meil.ru",
    "@maul.ru",
    "@tandex.ru",
    "@lmail.ru",
    "@email.ru",
    "@mail.eu",
    "@mll.ru",
    "@miil.ru",
    "@mai.ru",
    "@mal.ru",
    "@mail.com",
    "@mali.ru",
    "@mial.ru",
    "@yandez.ru",
    "@yanddx.ru",
    "@yanded.ru",
    "@yansex.ru",
    "@yandeex.ru",
    "@yamdex.ru",
    "@gkail.ru",
    "@gmil.com",
    "@gmal.com",
    "@gnail.com",
    "@gimail.com",
    "@gmmail.com",
    "@gamil.ru",
    "@ignail.com",
    "@gmail.ru",
    "@gmal.ru",
    "@gmai.com",
    "@gmai.ru",
    "@gmali.com",
    "@gmail.cm",
    "@dmail.com",
    "@bmail.com",
    "@vmail.com",
    "@fmail.com",
    "@tmail.com",
    "@gkail.com",
    "@gamil.com",
    "@hmail.ru",
    "@hotmail.ru",
    "@outlook.com",
    "@hotmail.com",
    "@outlook.ru",
    "@bo.ru",
    "@bj.ru",
    "@bl.ru",
    "@ilcoud.com",
    "@icloud.ru",
    "@icloudd.com",
    "@liat.ru",
    "@lisr.ru",
    "@invox.ru",
    "@stud.kphu.ru",
    "@kpfu.stud.ru",
    "@amil.ru",
    "@email.com",
    "@yandex.eu",
    "@gmaqil.com",
    "gmaik.com",
    "@interet.ru",
    "@iclod.com",
    "|",
  ];

  let ru = /[а-яё]+/i.test(email);
  let checkedEmail = invalidEmails.map((item) => {
    return email.indexOf(item) > -1;
  });
  return (
    checkedEmail.some((item) => {
      return item === true;
    }) || ru
  );
}
export function parseChildrenTrees(array) {
  let newArr = [];
  getChildren(array);
  function getChildren(arr) {
    arr.forEach((item) => {
      newArr.push(item);
      if (item?.children) {
        getChildren(item?.children?.data);
      }
    });
  }
  return newArr;
}
export function parseParentTrees(array, lastId) {
  let newArr = [];

  getChildren(array, lastId);

  function getChildren(arr, parentId) {
    arr.find((item) => {
      if (item.id === parentId) {
        newArr.push(item);
        getChildren(array, item.parent_id);
      }
    });
  }

  return newArr;
}
export async function setDisabledField(keyArray, stateFieldName, checkObject) {
  let sortedArr;
  if (keyArray) {
    sortedArr = Object.values(keyArray)
      ?.map((item) => {
        return Boolean(checkObject[item]);
      })
      .every((item) => {
        return item === true;
      });
  }
  return store.commit("user/setDisabledData", [stateFieldName, sortedArr]);
}
export function setCurrentCategoryYear(obj) {
  return obj?.year ?? moment().format("YYYY");
}
