import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { loadLayoutMiddleware } from "@/router/loadLayout.middleware";
import layoutsList from "@/enums/layoutsList";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404"),
    meta: {},
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/403"),
    meta: {},
  },
  {
    path: "/start",
    name: "NeedTelegram",
    component: () => import("@/views/NeedTelegram"),
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      noTelegram: true,
    },
  },
  {
    path: "/start-page",
    name: "StartPage",
    component: () => import("@/views/StartPage.vue"),
    meta: { layout: layoutsList.login, authFlow: true },
    children: [
      {
        path: "",
        name: "AuthStatus",
        component: () => import("@/views/StartPages/AuthStatus"),
      },
      {
        path: "sign-in",
        name: "Auth",
        component: () => import("@/views/StartPages/Auth"),
      },
      {
        path: "sign-up",
        name: "Registration",
        component: () => import("@/views/StartPages/Registration"),
      },
      // {
      //   path: "social-media",
      //   name: "SocialMedia",
      //   component: () => import("@/views/ProfilePages/SocialMedia"),
      //   meta: {
      //     checkField: ["isProfileFilled", "isEducationFilled"],
      //   },
      // },
      {
        path: "personal-data",
        name: "PersonalData",
        component: () => import("@/views/ProfilePages/PersonalData"),
        meta: {
          checkField: ["isProfileFilled", "isEducationFilled"],
        },
      },
    ],
  },
  {
    path: "/",
    name: "Events",
    component: () => import("@/views/Events.vue"),
    meta: { mainPage: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    children: [
      {
        path: "",
        name: "ProfileData",
        component: () => import("@/views/ProfilePages/ProfileData"),
        meta: { mainPage: true },
      },
      {
        path: "education",
        name: "Education",
        component: () => import("@/views/ProfilePages/Education"),
        meta: {
          checkField: ["isProfileFilled"],
          subPage: true,
        },
      },
      {
        path: "personal-data",
        name: "PersonalData",
        component: () => import("@/views/ProfilePages/PersonalData"),
        meta: {
          checkField: ["isProfileFilled", "isEducationFilled"],
          subPage: true,
        },
      },
    ],
  },
  {
    path: "/applications",
    name: "Applications",
    component: () => import("@/views/Applications.vue"),
    meta: {
      needProfileData: true,
      mainPage: true,
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("@/views/Portfolio.vue"),
    meta: {
      needProfileData: true,
      withRT: true,
      mainPage: true,
    },
  },
  // {
  //   path: "/appeal",
  //   name: "Appeal",
  //   component: () => import("@/views/Appeal.vue"),
  //   meta: {
  //     needProfileData: true,
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "Appeals",
  //       component: () => import("@/views/AppealPages/Appeals"),
  //     },
  //     {
  //       path: "/my-appeals",
  //       name: "MyAppeals",
  //       component: () => import("@/views/AppealPages/MyAppeals"),
  //     },
  //   ],
  // },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/Contacts.vue"),
    meta: { mainPage: true },
  },
  {
    path: "/performance",
    name: "Performance",
    component: () => import("@/views/Performance"),
    meta: {
      needProfileData: true,
      mainPage: true,
    },
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("@/views/Activity.vue"),
    meta: {
      needProfileData: true,
    },
    children: [
      {
        path: "",
        name: "SocialActivity",
        component: () => import("@/views/ActivityPages/SocialActivity.vue"),
        meta: { mainPage: true },
      },
      {
        path: "/labor-activity",
        name: "LaborActivity",
        component: () => import("@/views/ActivityPages/LaborActivity.vue"),
        meta: { subPage: true },
      },
    ],
  },
  {
    path: "/membership",
    name: "Membership",
    component: () => import("@/views/Membership"),
    meta: {
      needProfileData: true,
      mainPage: true,
    },
    children: [
      {
        path: "",
        name: "MembershipPerson",
        component: () => import("@/views/MembershipPages/MembershipPerson"),
      },
      {
        path: "/membership-committee",
        name: "MembershipCommittee",
        component: () => import("@/views/MembershipPages/MembershipCommittee"),
      },
    ],
  },
  {
    path: "/event-page/:id",
    name: "EventPage",
    component: () => import("@/views/EventPages/EventPage"),
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
    },
  },

  {
    path: "/social-need-form",
    name: "ExcellentStudentForm",
    component: () =>
      import("@/views/EventPages/TrasnportGrant/ExcellentStudentForm"),
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutForeign: true,
      withoutNotStudent: true,
      withRT: true,
    },
    children: [
      {
        path: "",
        name: "ExcellentStudentProfile",
        component: () =>
          import(
            "@/views/EventPages/TrasnportGrant/ExcellentStudentPages/ExcellentStudentProfile"
          ),
      },
      {
        path: "social-need-personal-data",
        name: "ExcellentStudentPersonalData",
        component: () => import("@/views/ProfilePages/PersonalData"),
        meta: {
          checkField: ["isProjectProfileFilled"],
        },
      },
      {
        path: "social-need-performance",
        name: "ExcellentStudentPerformance",
        component: () =>
          import(
            "@/views/EventPages/TrasnportGrant/ExcellentStudentPages/ExcellentStudentPerformance"
          ),
        meta: {
          checkField: ["isProjectProfileFilled", "isProjectPersonalFilled"],
        },
      },
    ],
  },
  {
    path: "/social-active-form",
    name: "SocialActiveForm",
    component: () =>
      import("@/views/EventPages/TrasnportGrant/SocialActiveForm"),
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutNotStudent: true,
      withRT: true,
    },
    children: [
      {
        path: "",
        name: "SocialActiveProfile",
        component: () =>
          import(
            "@/views/EventPages/TrasnportGrant/ExcellentStudentPages/ExcellentStudentProfile"
          ),
      },
      {
        path: "social-active-personal-data",
        name: "SocialActivePersonalData",
        component: () => import("@/views/ProfilePages/PersonalData"),
        meta: {
          checkField: ["isProjectProfileFilled"],
        },
      },
      {
        path: "social-active-performance",
        name: "SocialActivePerformance",
        component: () =>
          import(
            "@/views/EventPages/TrasnportGrant/ExcellentStudentPages/ExcellentStudentPerformance"
          ),
        meta: {
          checkField: ["isProjectProfileFilled", "isProjectPersonalFilled"],
        },
      },
      {
        path: "social-active-achievements",
        name: "SocialActiveAchievements",
        component: () =>
          import(
            "@/views/EventPages/TrasnportGrant/SocialActivePages/SocialActiveAchievements"
          ),
        meta: {
          checkField: [
            "isProjectProfileFilled",
            "isProjectPersonalFilled",
            "isProjectEducationFilled",
          ],
        },
      },
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/test"),
      },
    ],
  },

  {
    path: "/student-year-individual-form",
    name: "StudentYearIndividualForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutSpo: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import("@/views/EventPages/StudentYear/StudentYearIndividualForm"),
    children: [
      {
        path: "",
        name: "StudentYearIndividualNomination",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualNomination"
          ),
      },
      {
        path: "student-year-individual-data",
        name: "StudentYearIndividualData",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualData"
          ),
        meta: {
          checkField: ["isIndividualNominationFilled"],
        },
      },
      {
        path: "student-year-individual-performance",
        name: "StudentYearIndividualPerformance",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPerformance"
          ),
        meta: {
          checkField: ["isIndividualNominationFilled", "isSYPersonalFilled"],
        },
      },
      {
        path: "student-year-individual-portfolio",
        name: "StudentYearIndividualPortfolio",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPortfolio"
          ),
        meta: {
          checkField: [
            "isIndividualNominationFilled",
            "isSYPersonalFilled",
            "isIndividualEducationFilled",
          ],
        },
      },
    ],
  },
  {
    path: "/student-year-collective-form",
    name: "StudentYearCollectiveForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutSpo: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import("@/views/EventPages/StudentYear/StudentYearCollectiveForm"),
    children: [
      {
        path: "",
        name: "StudentYearCollectiveNomination",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualNomination"
          ),
      },
      {
        path: "student-year-collective-data",
        name: "StudentYearCollectiveData",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/CollectivePages/StudentYearCollectiveData"
          ),
        meta: {
          checkField: ["isCollectiveNominationFilled"],
        },
      },
      {
        path: "student-year-collective-performance",
        name: "StudentYearCollectivePerformance",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPerformance"
          ),
        meta: {
          checkField: [
            "isCollectiveNominationFilled",
            "isCollectiveDataFilled",
          ],
        },
      },
      {
        path: "student-year-collective-portfolio",
        name: "StudentYearCollectivePortfolio",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPortfolio"
          ),
        meta: {
          checkField: [
            "isCollectiveNominationFilled",
            "isCollectiveDataFilled",
            "isCollectiveEducationFilled",
          ],
        },
      },
    ],
  },
  {
    path: "/student-year-grandprix-form",
    name: "StudentYearGrandPrixForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutSpo: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import("@/views/EventPages/StudentYear/StudentYearGrandPrixForm"),
    children: [
      {
        path: "",
        name: "StudentYearGrandPrixNomination",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualNomination"
          ),
      },
      {
        path: "student-year-grand-prix-data",
        name: "StudentYearGrandPrixData",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualData"
          ),
        meta: {
          checkField: ["isGranprixNominationFilled"],
        },
      },
      {
        path: "student-year-grand-prix-performance",
        name: "StudentYearGrandPrixPerformance",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPerformance"
          ),
        meta: {
          checkField: ["isGranprixNominationFilled", "isSYPersonalFilled"],
        },
      },
      {
        path: "student-year-grand-prix-portfolio",
        name: "StudentYearGrandPrixPortfolio",
        component: () =>
          import(
            "@/views/EventPages/StudentYear/IndividualPages/StudentYearIndividualPortfolio"
          ),
        meta: {
          checkField: [
            "isGranprixNominationFilled",
            "isSYPersonalFilled",
            "isGranprixEducationFilled",
          ],
        },
      },
    ],
  },

  {
    path: "/achievement-year-individual-form",
    name: "AchievementYearIndividualForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutVuz: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import(
        "@/views/EventPages/AchievementYear/AchievementYearIndividualForm"
      ),
    children: [
      {
        path: "",
        name: "AchievementYearIndividualNomination",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualNomination"
          ),
      },
      {
        path: "achievement-year-individual-data",
        name: "AchievementYearIndividualData",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualData"
          ),
        meta: {
          checkField: ["isAYIndividualNominationFilled"],
        },
      },
      {
        path: "achievement-year-individual-performance",
        name: "AchievementYearIndividualPerformance",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPerformance"
          ),
        meta: {
          checkField: ["isAYIndividualNominationFilled", "isAYPersonalFilled"],
        },
      },
      {
        path: "achievement-year-individual-portfolio",
        name: "AchievementYearIndividualPortfolio",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPortfolio"
          ),
        meta: {
          checkField: [
            "isAYIndividualNominationFilled",
            "isAYPersonalFilled",
            "isAYIndividualEducationFilled",
          ],
        },
      },
    ],
  },
  {
    path: "/achievement-year-collective-form",
    name: "AchievementYearCollectiveForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutVuz: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import(
        "@/views/EventPages/AchievementYear/AchievementYearCollectiveForm"
      ),
    children: [
      {
        path: "",
        name: "AchievementYearCollectiveNomination",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualNomination"
          ),
      },
      {
        path: "achievement-year-collective-data",
        name: "AchievementYearCollectiveData",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/CollectivePages/AchievementYearCollectiveData"
          ),
        meta: {
          checkField: ["isAYCollectiveNominationFilled"],
        },
      },
      {
        path: "achievement-year-collective-performance",
        name: "AchievementYearCollectivePerformance",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPerformance"
          ),
        meta: {
          checkField: [
            "isAYCollectiveNominationFilled",
            "isAYCollectiveDataFilled",
          ],
        },
      },
      {
        path: "achievement-year-collective-portfolio",
        name: "AchievementYearCollectivePortfolio",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPortfolio"
          ),
        checkField: [
          "isAYCollectiveNominationFilled",
          "isAYCollectiveDataFilled",
          "isAYCollectiveEducationFilled",
        ],
      },
    ],
  },
  {
    path: "/achievement-year-grandprix-form",
    name: "AchievementYearGrandPrixForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutVuz: true,
      withoutNotStudent: true,
      withRT: true,
    },
    component: () =>
      import("@/views/EventPages/AchievementYear/AchievementYearGrandPrixForm"),
    children: [
      {
        path: "",
        name: "AchievementYearGrandPrixNomination",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualNomination"
          ),
      },
      {
        path: "achievement-year-grand-prix-data",
        name: "AchievementYearGrandPrixData",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualData"
          ),
        meta: {
          checkField: ["isAYGranprixNominationFilled"],
        },
      },
      {
        path: "achievement-year-grand-prix-performance",
        name: "AchievementYearGrandPrixPerformance",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPerformance"
          ),
        meta: {
          checkField: ["isAYGranprixNominationFilled", "isAYPersonalFilled"],
        },
      },
      {
        path: "achievement-year-grand-prix-portfolio",
        name: "AchievementYearGrandPrixPortfolio",
        component: () =>
          import(
            "@/views/EventPages/AchievementYear/IndividualPages/AchievementYearIndividualPortfolio"
          ),
        meta: {
          checkField: [
            "isAYGranprixNominationFilled",
            "isAYPersonalFilled",
            "isAYGranprixEducationFilled",
          ],
        },
      },
    ],
  },

  {
    path: "/housing-stock-form",
    name: "HousingStockForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withRT: true,
    },
    component: () => import("@/views/EventPages/HousingStock/HousingStockForm"),
    children: [
      {
        path: "",
        name: "HousingStockProfile",
        component: () =>
          import(
            "@/views/EventPages/HousingStock/HousingStockPages/HousingStockProfile"
          ),
      },
      {
        path: "social-activity",
        name: "HousingStockSocialActivity",
        component: () => import("@/views/ActivityPages/SocialActivity"),
        meta: {
          checkField: ["isHSProfileFilled"],
        },
      },
      {
        path: "labor-activity",
        name: "HousingStockLaborActivity",
        component: () => import("@/views/ActivityPages/LaborActivity"),
        meta: {
          checkField: ["isHSProfileFilled", "isSocialActivityFilled"],
        },
      },
      {
        path: "personal-data",
        name: "HousingStockPersonalData",
        component: () =>
          import(
            "@/views/EventPages/HousingStock/HousingStockPages/HousingStockPersonalData"
          ),
        meta: {
          checkField: [
            "isHSProfileFilled",
            "isSocialActivityFilled",
            "isLaborActivityFilled",
          ],
        },
      },
      {
        path: "documents",
        name: "HousingStockDocs",
        component: () =>
          import(
            "@/views/EventPages/HousingStock/HousingStockPages/HousingStockDocs"
          ),
        meta: {
          checkField: [
            "isHSProfileFilled",
            "isSocialActivityFilled",
            "isLaborActivityFilled",
            "isHSPersonalDataFilled",
          ],
        },
      },
    ],
  },

  {
    path: "/liga-forums-first-shift-form",
    name: "LigaForumFirstShiftForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutNotStudent: true,
    },
    component: () =>
      import("@/views/EventPages/LigaForum/LigaForumFirstShiftForm.vue"),
    children: [
      {
        path: "",
        name: "LigaForumFirstShiftProfile",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumProfile.vue"
          ),
      },
      {
        path: "/tracks",
        name: "LigaForumFirstShiftTracks",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumTracks.vue"
          ),
        meta: {
          checkField: ["isLFFirstShiftProfileFilled"],
        },
      },
      {
        path: "/info",
        name: "LigaForumFirstShiftInfo",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumInfo.vue"
          ),
        meta: {
          checkField: [
            "isLFFirstShiftTracksFilled",
            "isLFFirstShiftProfileFilled",
          ],
        },
      },
    ],
  },
  {
    path: "/liga-forums-second-shift-form",
    name: "LigaForumSecondShiftForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutNotStudent: true,
    },
    component: () =>
      import("@/views/EventPages/LigaForum/LigaForumSecondShiftForm.vue"),
    children: [
      {
        path: "",
        name: "LigaForumSecondShiftProfile",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumProfile.vue"
          ),
      },
      {
        path: "/tracks",
        name: "LigaForumSecondShiftTracks",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumTracks.vue"
          ),
        meta: {
          checkField: ["isLFSecondShiftProfileFilled"],
        },
      },

      {
        path: "/info",
        name: "LigaForumSecondShiftInfo",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumInfo.vue"
          ),
        meta: {
          checkField: [
            "isLFSecondShiftTracksFilled",
            "isLFSecondShiftProfileFilled",
          ],
        },
      },
    ],
  },
  {
    path: "/liga-forums-third-shift-form",
    name: "LigaForumThirdShiftForm",
    meta: {
      withoutSidebar: true,
      layout: layoutsList.noSidebar,
      withoutNotStudent: true,
    },
    component: () =>
      import("@/views/EventPages/LigaForum/LigaForumThirdShiftForm.vue"),
    children: [
      {
        path: "",
        name: "LigaForumThirdShiftProfile",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumProfile.vue"
          ),
      },
      {
        path: "/tracks",
        name: "LigaForumThirdShiftTracks",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumTracks.vue"
          ),
        meta: {
          checkField: ["isLFThirdShiftProfileFilled"],
        },
      },

      {
        path: "/info",
        name: "LigaForumThirdShiftInfo",
        component: () =>
          import(
            "@/views/EventPages/LigaForum/LigaForumPages/LigaForumInfo.vue"
          ),
        meta: {
          checkField: [
            "isLFThirdShiftTracksFilled",
            "isLFThirdShiftProfileFilled",
          ],
        },
      },
    ],
  },

  {
    path: "/requisites",
    name: "Requisites",
    component: () => import("@/views/docs/Requisites"),
    meta: { withoutSidebar: true },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/docs/Terms"),
    meta: { withoutSidebar: true },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/docs/Privacy"),
    meta: { withoutSidebar: true },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("@/views/docs/Policy"),
    meta: { withoutSidebar: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach(loadLayoutMiddleware);
router.beforeEach(async (to, from, next) => {
  next();
  if (store.state.isFrontVersionLoading) return;
  store.commit("setFrontVersionChanged", false);

  if (from.meta.mainPage && !to.meta.subPage) {
    store.commit("updateStateValue", {
      key: "isFrontVersionLoading",
      value: true,
    });
  }

  await store.dispatch("getFrontInfo");
  store.commit("updateStateValue", {
    key: "isFrontVersionLoading",
    value: false,
  });
});
router.beforeEach((to, from, next) => {
  if (!to.meta.authFlow && !store.state.user.isAuthenticated)
    next({ name: "AuthStatus" });
  else if (to.meta.authFlow && store.state.user.isAuthenticated) {
    next({ name: "Events" });
  } else if (
    to.meta.needProfileData &&
    !store.state.user.disabledFields.isEducationFilled
  ) {
    next({ name: "Events" });
  } else if (to.meta.checkField) {
    to.meta.checkField.every((item) => {
      return store.state.user.disabledFields[item] === true;
    })
      ? next()
      : next({ name: "Events" });
  } else if (to.meta.withoutSpo && store.state.user.isSPO) {
    next({ name: "Events" });
  } else if (to.meta.withRT && !store.state.user.isRT) {
    next({ name: "Events" });
  } else if (to.meta.withoutVuz && !store.state.user.isSPO) {
    next({ name: "Events" });
  } else if (
    to.meta.withoutForeign &&
    !store.state.user.disabledFields.isCitizenRussia
  ) {
    next({ name: "Events" });
  } else if (
    to.meta.withoutNotStudent &&
    store.state.user.disabledFields.isNotStudent
  ) {
    next({ name: "Events" });
  } else next();
});
router.beforeEach((to, from, next) => {
  if (
    !to.meta.noTelegram &&
    !!store.state.user.isTelegram &&
    store.state.user.isAuthenticated
  ) {
    next({ name: "NeedTelegram" });
  } else next();
});

export default router;
