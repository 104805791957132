import moment from "moment";

export const infoStatements = (state) => {
  return state.user?.info_statements?.data;
};

export const isTelegramLink = (state) => {
  return state.user?.telegram_link;
};
export const infoFix = (state) => {
  let infoFix = state.user?.info_fix;
  if (infoFix) {
    Object.keys(infoFix).forEach((item) => {
      if (infoFix[item]) {
        if (item.includes("_at") || item === "birthday") {
          infoFix[item] = moment(infoFix[item]).format("YYYY-MM-DD");
        }
      }
      if (item.includes("_format")) {
        delete infoFix[item];
      }
    });
  }
  return infoFix;
};
export const infoFixPassport = (state) => {
  let infoFixPassport = state.user?.info_fix?.passport;
  if (infoFixPassport) {
    Object.keys(infoFixPassport).forEach((item) => {
      if (infoFixPassport[item] && item.includes("_at")) {
        infoFixPassport[item] = moment(infoFixPassport[item]).format(
          "YYYY-MM-DD"
        );
      }
      if (item.includes("_format") || !item) {
        delete infoFixPassport[item];
      }
    });
  }
  return infoFixPassport;
};
