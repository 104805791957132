<template>
  <div class="file" @click="fileUploadTrigger" :class="className">
    <input
      :id="uniqueId"
      type="file"
      name="file"
      ref="file"
      @change="openPopup($event)"
      class="file__input"
    />
    <div class="btn btn--small docs-upload__btn">
      <slot></slot>
      <label :for="uniqueId" class="file__label">
        {{ name }}
      </label>
    </div>
    <div class="file__preloader">
      <div class="file__anim"></div>
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { getBrowserVersion } from "@/utils/getBrowserVersion";

export default {
  name: "InputFile",
  data() {
    return {
      uniqueId: uniqueString(),
      fileError: "",
      isErrorShown: false,
      timeout: null,
    };
  },
  watch: {
    "$store.state.currentPopup.props"(newValue, oldValue) {
      if (newValue.cropImgPOST !== oldValue.cropImgPOST) {
        this.handleFileUpload();
      }
    },
  },
  props: {
    name: {
      type: String,
    },
    cropperType: String,
    isLoading: Boolean,
    currentDocsOrientation: String,
    keyName: String,
    expectedFormats: {
      type: Array,
      default: () => {
        return ["image/jpg", "image/jpeg", "image/png", "image/bmp"];
      },
    },
    fileUploadStatuses: {
      type: Array,
      default: () => {
        return [
          "Размер файла слишком велик",
          "Неверный формат файла",
          '"Название файла не должно содержать символы: */:<>?\\\\|\\\\"',
        ];
      },
    },
    invalidChars: {
      type: Array,
      default: () => {
        return ["*", "/", ":", "<", ">", "?", "\\", "|", "\\"];
      },
    },
    small: Boolean,
  },
  computed: {
    className() {
      return {
        "file--loading": this.isLoading,
        "btn--small": this.small,
      };
    },
    docsOrientation() {
      let orientaion;
      if (this.currentDocsOrientation === "horizontal") {
        orientaion = 16 / 9;
      }
      if (this.currentDocsOrientation === "vertical") {
        orientaion = 3 / 4;
      }
      if (this.currentDocsOrientation === "free") {
        orientaion = 0;
      }
      return orientaion;
    },
    browserInfo() {
      return getBrowserVersion();
    },
  },
  methods: {
    async saveImg(file) {
      if (this.cropperType === "avatar") {
        let payloadObject = {};
        payloadObject.avatar = file;

        await createRequest(requestConfigs.POSTSaveInfo, {
          jsonPayload: payloadObject,
        })
          .then(() => {
            this.$store.dispatch("user/getUserInfo");
            this.$store.commit("setServerAvatar", "");
            this.$store.commit("pushToTray", {
              text: "Данные успешно сохранены",
              type: "success",
            });
          })
          .catch((error) => {
            this.$store.commit("pushToTray", {
              text: error.errors[0].error_descr,
              type: "error",
            });
          });
      } else {
        await this.$store.commit("setDocument", [file, this.keyName]);
        await this.$store.commit("pushToTray", {
          text: "Файл загружен, необходимо сохранить данные",
          type: "info",
        });
      }
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    fileUploadTrigger() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.$emit(
        "loadingFile",
        this.$store.state.currentPopup.props.cropImgPOST
      );
    },
    fileValidation(file) {
      if (!this.expectedFormats.includes(file.type)) {
        this.$store.commit("pushToTray", {
          text: this.fileUploadStatuses[1],
          type: "error",
        });
        return false;
      } else if (this.stringValidator(file.name)) {
        this.$store.commit("pushToTray", {
          text: this.fileUploadStatuses[2],
          type: "error",
        });
        return false;
      } else return true;
    },
    stringValidator(str) {
      let arr;
      arr = this.invalidChars.filter((item) => {
        if (str.includes(item)) return true;
      });
      return arr.length > 0;
    },
    openPopup(event) {
      if (this.isLoading) return;
      if (!this.fileValidation(event.target.files[0])) return;

      const isOsMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

      if (this.browserInfo.browser === "Safari" || isOsMobile) {
        this.saveImg(event.target.files[0]);
      } else
        this.$store.commit("setCurrentPopup", {
          name: "PopupCropper",
          isShown: true,
          props: {
            cropperType: this.cropperType,
            popupClass: "popup-cropper",
            file: event.target.files[0],
            aspect: this.docsOrientation,
            orientation: this.currentDocsOrientation,
            keyName: this.keyName,
          },
        });
      this.$refs.file.value = null;
    },
  },
};
</script>

<style lang="scss"></style>
