<template>
  <div class="popup-delete-achievement">
    <div class="popup-delete-achievement__text">
      Вы уверены, что хотите удалить? Все данные будут утеряны
    </div>
    <div class="popup-delete-achievement__btn">
      <Button secondary @click="deletePortfolioItem">Удалить</Button>
      <Button @click="closePopup">Отмена</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
export default {
  name: "PopupDeleteAchievement",
  components: { Button },
  computed: {
    ...mapState(["currentPopup"]),
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    async deletePortfolioItem() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
      this.$store.commit("setDeleteBtnLoading", true);
      if (this.currentPopup.props.type === "achievements") {
        await createRequest(requestConfigs.DELStatementDocument, {
          jsonPayload: { media_id: this.currentPopup.props.media_id },
          routerPayload: { id: this.currentPopup.props.id },
        })
          .then(() => {
            this.$store
              .dispatch(
                "deleteToAchievementsList",
                this.currentPopup?.props?.media_id
              )
              .finally(() => {
                this.$store.commit("setDeleteBtnLoading", false);
              });
          })
          .finally(() => {
            this.$store.commit("setDeleteBtnLoading", false);
          });
      } else
        await createRequest(requestConfigs.DELPortfolioItem, {
          routerPayload: { id: this.currentPopup.props.id },
        }).then(() => {
          this.$store.dispatch(
            "deleteFromNewPortfolioList",
            this.currentPopup?.props?.id
          );
          this.$store
            .dispatch("deleteFromPortfolioList", this.currentPopup?.props?.id)
            .finally(() => {
              this.$store.commit("setDeleteBtnLoading", false);
            });
        });
    },
  },
};
</script>

<style lang="scss">
.popup-delete-achievement {
  &__text {
    margin-bottom: 16px;
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
    @include adaptive(phone) {
      justify-content: center;
    }
  }
}
</style>
