<template>
  <div class="popup-cropper__content">
    <div class="popup-cropper__title">
      {{
        currentPopup.props.cropperType === "avatar"
          ? "Загрузить аватар"
          : "Загрузить документ"
      }}
    </div>
    <div class="popup-cropper__viewport" v-if="imgSrc">
      <vue-cropper
        class="popup-cropper__cropper"
        :class="{
          'popup-cropper__cropper--avatar':
            currentPopup.props.cropperType === 'avatar',
        }"
        ref="cropper"
        :guides="true"
        :view-mode="2"
        drag-mode="crop"
        :auto-crop-area="0.7"
        :min-container-width="250"
        :min-container-height="180"
        :background="true"
        :restore="false"
        :rotatable="currentPopup.props.cropperType !== 'avatar'"
        :zoomable="true"
        :scale="1"
        :scalebale="true"
        :responsive="true"
        :checkOrientation="false"
        :src="imgSrc"
        alt="Source Image"
        :imgStyle="{ maxWidth: '525px', maxHeight: '525px' }"
        :containerStyle="{ maxWidth: '525px', maxHeight: '525px' }"
      />
    </div>
    <div
      class="popup-cropper__btn-action"
      v-if="$store.state.currentPopup.props.cropperType !== 'avatar'"
    >
      <div class="popup-cropper__desc">
        <p>
          Изображение должно быть
          {{
            this.currentPopup.props.orientation === "vertical"
              ? "вертикальным"
              : "горизонтальным"
          }}
        </p>
        <p>Используйте колесо мыши для увеличения/уменьшения изображения</p>
      </div>
      <Button icon-type="Sync" cropper @click="imageRotate">
        Повернуть на 90 градусов
      </Button>
    </div>
    <div class="popup-cropper__btn">
      <Button @click="cropImage">Загрузить фотографию</Button>
      <Button @click="closePopup" secondary>Отмена</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";

export default {
  name: "PopupCropper",
  components: { Button, VueCropper },
  data() {
    return {
      imgSrc: "",
      cropImgName: "",
      cropImgPOST: "",
      compressedImg: "",
      compressedSize: "",
    };
  },
  computed: {
    ...mapState(["currentPopup", "docs"]),
  },
  methods: {
    compress(img, compressNum) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = img.width;
      const height = img.height;
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);

      const ndata = canvas.toDataURL("image/webp", compressNum);
      return ndata;
    },
    async checkSize() {
      await this.$store.commit("setCurrentPopup", {
        isShown: false,
      });

      await this.$store.commit("pushToTray", {
        text: "Не удалось сохранить данные. Размер файла слишком велик",
        type: "error",
      });
    },
    setImage(e) {
      // vm = this;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = event.target.result;
      };
      reader.readAsDataURL(e);
    },
    async saveImg() {
      if (this.currentPopup.props.cropperType === "avatar") {
        await this.$store.commit(
          "setDontSavedCrop",
          this.$refs.cropper?.getCroppedCanvas().toDataURL()
        );

        let payloadObject = {};
        payloadObject.avatar = this.cropImgPOST;

        await createRequest(requestConfigs.POSTSaveInfo, {
          jsonPayload: payloadObject,
        })
          .then(() => {
            this.$store.dispatch("user/getUserInfo");
            this.$store.commit("setServerAvatar", "");
            this.$store.commit("pushToTray", {
              text: "Данные успешно сохранены",
              type: "success",
            });
          })
          .catch((error) => {
            this.$store.commit("pushToTray", {
              text: error.errors[0].error_descr,
              type: "error",
            });
          });
      } else {
        await this.$store.commit("setDocument", [
          this.cropImgPOST,
          this.currentPopup.props.keyName,
        ]);
        await this.$store.commit("pushToTray", {
          text: "Файл загружен, необходимо сохранить данные",
          type: "info",
        });
      }
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    imageRotate() {
      this.$refs.cropper.rotate(90);
    },

    async cropImage(vm) {
      let base64Image = await fetch(
        this.$refs.cropper?.getCroppedCanvas().toDataURL("image/webp", 0.5)
      );

      vm = this;
      const img = new Image();
      img.src = base64Image.url;
      img.onload = await async function () {
        vm.compressedImg = vm.compress(img, 0.5);
        let base64Response = await fetch(vm.compressedImg);
        vm.cropImgPOST = await base64Response.blob();
        if (vm.cropImgPOST.size > 2097152) {
          await vm.checkSize();
          return;
        }
        await vm.saveImg();
      };
    },

    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
  },
  beforeMount() {
    this.setImage(this.currentPopup.props.file);
  },
};
</script>

<style lang="scss">
.popup-cropper {
  &.popup-wrapper {
    .modal-box {
      max-width: 625px;
    }
  }
  &__title {
    margin-top: 0;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 12px;
    @include adaptive(phone) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__cropper {
    margin-bottom: 20px;
    border: 1px solid var(--background--btn--profile--hover);
    &--avatar {
      .cropper-view-box,
      .cropper-face {
        border-radius: 50%;
      }
    }
  }
  &__desc {
  }
  &__btn-action {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    @include adaptive(phone) {
      flex-direction: column;
      & button {
        align-self: center;
      }
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
    justify-content: center;
    @include adaptive(phone) {
      flex-direction: column;
      & button {
        align-self: center;
      }
    }
  }
}
</style>
