<template>
  <div class="alert" :class="className">
    <span><Icon class="icon-Warning alert__icon--warning" /> </span>
    <span><Icon class="icon-ok alert__icon--ok" /></span>
    <p>{{ label.text }}</p>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Alert",
  components: { Icon },
  data() {
    return {
      timeout: null,
    };
  },
  props: {
    label: Object,
    warning: Boolean,
    success: Boolean,
    info: Boolean,
  },
  computed: {
    className() {
      return {
        "alert--warning": this.label.type[0] === "error",
        "alert--success": this.label.type[0] === "success",
        "alert--info": this.label.type[0] === "info",
      };
    },
  },
  mounted() {
    this.timeout = setTimeout(
      () => this.$store.commit("removeFromTray", this.label.id),
      4000
    );
  },
};
</script>

<style lang="scss">
.alert {
  display: flex;
  gap: 5px;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 10px;
  padding: 14px;
  transition: all 0.3s ease;
  &__icon--warning,
  &__icon--ok {
    display: none;
  }
  &--warning {
    background-color: red;
    .alert__icon--warning {
      display: block;
    }
  }
  &--success {
    background-color: #10b981;
    .alert__icon--ok {
      display: block;
    }
  }
  &--info {
    background-color: darkorange;
    .alert__icon--warning {
      display: block;
    }
  }
}
</style>
