<template>
  <div class="popup-wrapper">
    <div class="modal-box">
      <div class="popup-view-certificate">
        <Icon
          @click="closePopup"
          class="icon-Close popup-view-certificate__icon"
        />
        <div class="popup-view-certificate__img" @click="loadImage">
          <Skeleton v-if="isOriginalPictureLoading" />
          <img :src="imgSrcWithHash" alt="" v-else ref="image" />
        </div>
        <div class="popup-view-certificate__btn" @click="rotateImg">
          <Button icon-type="Sync" cropper> Повернуть на 90 градусов </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import { mapGetters, mapState } from "vuex";
import Skeleton from "@/components/Blocks/Skeleton";
import { getImg } from "@/utils/helpers";
import Button from "@/components/Blocks/Button";
export default {
  name: "PopupViewCertificate",
  components: { Button, Skeleton, Icon },
  data() {
    return {
      isOriginalPictureLoading: false,
      originalImage: "",
      currentRotate: 0,
    };
  },
  computed: {
    imgSrc() {
      return this.portfolio_image
        ? this.portfolio_image
        : `data:image/png;base64,${this.originalImage}`;
    },
    imgSrcWithHash() {
      return this.hashMedia ? this.imgSrc : this.originalImage;
    },
    ...mapState(["currentPopup"]),
    ...mapGetters(["hashMedia"]),
  },
  props: ["image_url", "portfolio_image"],
  watch: {
    currentPopup() {
      this.loadImage();
    },
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    loadImage() {
      if (!this.portfolio_image) {
        if (this.hashMedia) {
          this.isOriginalPictureLoading = true;
          getImg(this.image_url)
            .then((result) => {
              this.originalImage = result;
            })
            .finally(() => {
              this.isOriginalPictureLoading = false;
            });
        } else this.originalImage = this.image_url;
      } else this.originalImage = this.portfolio_image;
    },
    rotateImg() {
      this.currentRotate += 90;
      this.$refs.image.style.transform =
        "rotate(" + this.currentRotate + "deg)";
    },
    closePopupEsc(e) {
      if (e.key === "Escape") this.closePopup();
    },
  },
  mounted() {
    this.loadImage();
    window.addEventListener("keydown", this.closePopupEsc);
  },
  unmounted() {
    window.removeEventListener("keydown", this.closePopupEsc);
  },
};
</script>

<style lang="scss">
.popup-view-certificate {
  display: flex;
  flex-direction: column;
  &__icon {
    align-self: flex-end;
    cursor: pointer;
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: var(--color--disabled);
  }
  &__img {
    position: relative;
    flex: 0 1 560px;
    overflow: hidden;
    @include adaptive(tablet-small) {
      flex: 0 1 480px;
    }
    @include adaptive(phone) {
      flex: 0 1 300px;
    }
    & img {
      @include full-absolute();
      object-fit: contain;
      aspect-ratio: 1/1;
    }
  }
  &__btn {
    align-self: center;
    margin-top: 20px;
    pointer-events: visible;
    @include adaptive(tablet-small) {
      display: block;
      margin-top: 15px;
    }
  }
}
</style>
