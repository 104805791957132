//определяем браузер и его версию
function getBrowserVersion() {
  if (typeof window == "undefined")
    throw "you can't determine browser in SSR side";

  const userAgent = navigator.userAgent;
  const browserName =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  if (/trident/i.test(browserName[0])) {
    const rv = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { browser: "IE", version: rv[1] };
  }

  const version = browserName[2] ? browserName[2] : null;
  const browser = browserName[1] ? browserName[1] : null;

  return {
    browser: browser,
    version: version,
  };
}

function isOldChrome() {
  const browserInfo = getBrowserVersion();
  return (
    browserInfo.browser === "Chrome" &&
    Number(browserInfo.version) >= 12 &&
    Number(browserInfo.version) <= 112
  );
}

export { getBrowserVersion, isOldChrome };
