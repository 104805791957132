export default [
  {
    link: "https://lk.ligastudentov.com/",
    title: "Транспортный грант",
  },
  {
    link: "https://liga-forum.ru/",
    title: "Лига форум",
  },
  {
    link: "https://lk.ligastudentov.com/",
    title: "Молодёжный жилищный конкурс",
  },
  {
    link: "https://ligastudentov.com/projects/student_goda",
    title: "Студент года",
  },
  {
    link: "https://ligastudentov.com/projects/student_goda/statistic",
    title: "Победители премии «Студент года»",
  },
  {
    link: "https://ligastudentov.com/projects/student_goda/winners",
    title: "Статистика участников «Студент года»",
  },
  {
    link: "https://ligastudentov.com/projects/dostizhenie_goda",
    title: "Победители премии «Достижение года» ",
  },
  {
    link: "https://ligastudentov.com/projects/dostizhenie_goda/winners",
    title: "Достижение года",
  },
  {
    link: "https://ligastudentov.com/projects/dostizhenie_goda/statistic",
    title: "Статистика участников «Достижение года»",
  },
  {
    link: "https://ligastudentov.com/history",
    title: "История Лиги студентов",
  },
  {
    link: "https://ligastudentov.com/about",
    title: "Про Лигу студентов",
  },
];
