<template>
  <div class="skeleton-basic" />
</template>

<script>
export default {
  name: "Skeleton",
};
</script>

<style lang="scss">
.skeleton-basic {
  background: linear-gradient(269deg, #74859f, #e1e2e3, #74859f);
  background-size: 400% 400%;
  animation: shine 1s ease infinite;
  width: 100%;
  height: 100%;
}
@keyframes shine {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
