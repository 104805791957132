<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__body">
        <button @click="navOpen = !navOpen" class="header__burger">
          <Icon class="icon-burger" />
        </button>
        <a
          href="https://ligastudentov.com/"
          target="_blank"
          class="header__main-site"
        >
          На главную
        </a>
        <transition name="translateX">
          <nav class="burger-nav" v-show="navOpen">
            <div class="burger-nav__wrapper">
              <Icon
                class="icon-Close burger-nav__close"
                @click="navOpen = !navOpen"
              />
              <div class="burger-nav__content burger-content">
                <div class="burger-nav__user">
                  <div class="burger-nav__img">
                    <Skeleton v-if="isInfoLoading" />
                    <Skeleton v-else-if="isPictureLoading" />
                    <img
                      v-else
                      :src="$store.state.dontSavedCrop ?? avatarSrc"
                      alt="Аватар"
                    />
                    <a @click="openPopup"></a>
                  </div>
                  <Skeleton v-if="isInfoLoading" class="burger-nav__username" />
                  <div class="burger-nav__username" v-else>
                    {{
                      user.last_name
                        ? `${user.last_name} ${user.first_name}`
                        : "Пользователь"
                    }}
                  </div>
                </div>
                <div class="burger-nav__btn">
                  <a
                    href="https://ligastudentov.com/"
                    target="_blank"
                    class="burger-nav__main-site"
                  >
                    На главную
                  </a>
                  <router-link
                    class="burger-nav__link"
                    v-for="(item, index) in filteredNavigationLinks"
                    :to="item.link"
                    :key="index"
                    active-class="burger-link-active"
                    :class="{
                      'burger-link--disabled':
                        item.disabled || isFrontVersionLoading,
                      tooltip: item.disabled,
                    }"
                    tooltip="Для того, чтобы воспользоваться
                             всем функционалом сайта
                             вам необходимо заполнить профиль"
                  >
                    <span @click="linkClick(item.link)">
                      {{ item.text }}
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </nav>
        </transition>
        <router-link class="header__logo" :to="{ name: 'Events' }">
          <img src="@/assets/logo.svg" alt="" />
        </router-link>
        <Button class="header__btn" secondary @click="logOut"> Выйти </Button>
        <div class="header__exit">
          <Icon class="icon-exit" @click="logOut" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Button from "@/components/Blocks/Button";
import Icon from "@/components/Blocks/Icon";
import navigationLinks from "@/enums/navigationLinks";
import { mapGetters, mapState } from "vuex";
import Skeleton from "@/components/Blocks/Skeleton";
import { getImg } from "@/utils/helpers";
export default {
  name: "Header",
  components: { Skeleton, Icon, Button },
  data() {
    return {
      navOpen: false,
      navigationLinks,
      isPictureLoading: false,
      width: window.innerWidth,
    };
  },
  props: ["isInfoLoading", "avatar"],
  watch: {
    navOpen: function () {
      this.navOpen
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
    avatar() {
      // if (this.isMobile) return;
      this.loadInfo();
    },
  },

  computed: {
    avatarSrc() {
      let avatar;
      if (this.serverAvatar) {
        if (this.hashMedia) {
          avatar = `data:image/png;base64,${this.serverAvatar}`;
        } else avatar = this.serverAvatar;
      } else avatar = require("@/assets/Person.svg");
      return avatar;
    },
    isMobile() {
      return this.width < 768;
    },
    filteredNavigationLinks() {
      let buffer = JSON.parse(JSON.stringify(this.navigationLinks));

      return buffer.map((item) => {
        if (item.meta === "disabled") {
          item.disabled = !this.disabledFields["isEducationFilled"];
        }
        return item;
      });
    },
    ...mapState("user", ["user", "disabledFields"]),
    ...mapState(["serverAvatar", "isFrontVersionLoading"]),
    ...mapGetters(["hashMedia"]),
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
    logOut() {
      // this.$store.commit("user/killUser");
      this.$store.dispatch("logout");
      this.$store.commit("setServerAvatar", "");
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupAvatarTerms",
        isShown: true,
        props: {},
      });
    },
    loadInfo() {
      if (this.serverAvatar) return;
      if (this.avatar) {
        if (this.hashMedia) {
          getImg(this.avatar)
            .then((result) => {
              this.$store.commit("setServerAvatar", result);
            })
            .finally(() => {
              this.isPictureLoading = false;
            });
        } else this.$store.commit("setServerAvatar", this.avatar);
      }
    },
    linkClick(value) {
      this.navOpen = !this.navOpen;
    },
  },
  mounted() {
    // if (!this.isMobile) return;
    this.loadInfo();
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
.header {
  background-color: var(--background--main);
  width: 100%;

  &__wrapper {
    max-width: 1560px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: content-box;
    @include adaptive(phone) {
      padding: 15px 20px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
  &__logo {
    grid-column: 2 / 3;
    justify-self: center;
    align-self: center;
    @include adaptive(phone) {
      width: 54px;
      height: 24px;
    }
  }
  &__btn {
    justify-self: end;
    align-self: center;
    @include adaptive(tablet-small) {
      display: none;
    }
  }
  &__exit {
    color: var(--white);
    align-self: center;
    justify-self: end;
    display: none;
    cursor: pointer;
    &:hover {
      -webkit-text-stroke-width: 0.1rem;
    }
    @include adaptive(tablet-small) {
      display: block;
    }
    & i {
      font-size: 2.4rem;
      display: flex;
      @include adaptive(phone) {
        font-size: 1.8rem;
      }
    }
  }
  &__main-site {
    color: #fff;
    font-weight: 600;
    font-size: 1.6rem;
    max-width: 150px;
    &:hover {
      -webkit-text-stroke-width: 0.05rem;
    }
    @include adaptive(tablet-small) {
      display: none;
    }
  }
  &__burger {
    color: var(--white);
    justify-self: start;
    align-self: center;
    &:hover {
      -webkit-text-stroke-width: 0.1rem;
    }
    & i {
      font-size: 2.2rem;
      @include adaptive(phone) {
        font-size: 1.5rem;
      }
    }
    display: none;
    @include adaptive(tablet-small) {
      display: flex;
    }
  }
}
.burger-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  @include adaptive(tablet-small) {
    display: block;
  }
  &__wrapper {
    padding: 48px 20px 0 20px;
    width: 220px;
    background-color: var(--background--main);
    position: relative;
    height: 100%;
    color: var(--white);
    transition: 0.2s ease-in-out;
    @include adaptive(phone) {
      width: 100%;
      padding-left: 50px;
    }
    & a {
      color: var(--background--btn--profile--hover);
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: var(--white);
    font-size: 1.4rem;
  }
  &__content {
  }
  &__user {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 40px;
  }
  &__username {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    &.skeleton-basic {
      width: 100px;
      height: 2.7rem;
      border-radius: 5px;
    }
  }
  &__img {
    position: relative;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    background-color: var(--background--disabled);
    border-radius: 50%;
    & img {
      @include full-absolute();
      object-fit: cover;
      border-radius: 50%;
    }
    & a {
      position: absolute;
      top: 90%;
      left: -25%;
      &:after {
        content: "\e91a";
        font-family: "icomoon";
        font-size: 1.8rem;
        color: var(--white);
        padding: 7px;
        border: 2px solid var(--white);
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        @include adaptive(tablet-big) {
          font-size: 1.2rem;
          padding: 6px;
        }
      }
      &:hover {
        &:after {
          background-color: var(--white);
          color: var(--text--color);
        }
      }
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  &__main-site {
    font-weight: 600;
    font-size: 2rem;
    padding-left: 10px;
    text-decoration: underline;
  }
  &__link {
    padding-left: 10px;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 500;
  }
  .burger-link-active {
    border-left: 2px solid #e31a5a;
  }
  .burger-link--disabled {
    pointer-events: none;
    span {
      color: var(--color--disabled);
      pointer-events: none;
    }
  }
}

.translateX-enter {
  transform: translateX(-200px);
  opacity: 0;
}
.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}

.translateX-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
</style>
