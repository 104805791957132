<template>
  <component
    :is="$route.meta.layoutComponent"
    :info="user"
    :isInfoLoading="isInfoLoading"
  >
    <slot />
  </component>
</template>
<script>
import { mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import store from "@/store";

export default {
  name: "AppLayout",
  data() {
    return {
      isInfoLoading: false,
    };
  },
  watch: {
    async frontVersion() {
      this.$store.commit("setServerAvatar", "");
      this.$store.commit("user/killUserFull");
      store.commit("setFrontVersionChanged", true);

      this.$store.commit("setStorageInfoLoading", true);
      const [systemInfo, projectsInfo] = await Promise.all([
        createRequest(requestConfigs.GETSystemInfo),
        createRequest(requestConfigs.GETSProjectsList),
      ]);
      this.$store.commit("setStorageData", [systemInfo, "systemInfo"]);
      this.$store.commit("setStorageData", [
        projectsInfo?.data,
        "projectsList",
      ]);
      store.commit("setStorageInfoLoading", false);

      if (this.isAuthenticated) {
        this.getUserInfo();
      }
    },
    $route() {
      if (this.isTelegram) {
        this.$store.commit("user/setIsTelegramInfo", true);
      }
      this.checkProfile();
    },
  },
  computed: {
    ...mapState(["trayItems", "storage", "frontVersion"]),
    ...mapState("user", [
      "user",
      "isAuthenticated",
      "isTelegram",
      "setIsProfileMustEdit",
      "setIsPassportMustEdit",
    ]),
  },
  methods: {
    checkProfile() {
      if (
        this.isAuthenticated &&
        !this.isTelegram &&
        this.$route.name !== "ProfileData" &&
        this.$route.name !== "PersonalData"
      ) {
        if (this.setIsProfileMustEdit || this.setIsPassportMustEdit) {
          this.$store.commit("setCurrentPopup", {
            name: "PopupMustEdit",
            isShown: true,
          });
        }
      }
    },
    getSystemInfo() {
      createRequest(requestConfigs.GETSystemInfo).then((result) => {
        this.$store.commit("setStorageData", [result, "systemInfo"]);
      });
    },
    getProjectsInfo() {
      createRequest(requestConfigs.GETSProjectsList).then((result) => {
        this.$store.commit("setStorageData", [result.data, "projectsList"]);
      });
    },
    async getUserInfo() {
      this.isInfoLoading = true;
      await this.$store.dispatch("user/getUserInfo");
      this.isInfoLoading = false;
    },
    clearLocalStorage() {
      if (!this.isAuthenticated) {
        this.$store.commit("user/killUserFull");
        this.$store.dispatch("logout");
      }
    },
  },
  async beforeMount() {
    if (this.isAuthenticated) {
      this.getUserInfo();
    }
    this.checkProfile();
    if (Object.keys(this.storage.systemInfo).length === 0) this.getSystemInfo();
    if (this.storage.projectsList.length === 0) this.getProjectsInfo();
  },
};
</script>

<style scoped lang="scss"></style>
