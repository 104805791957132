<template>
  <footer
    itemscope
    itemtype="https://schema.org/Organization"
    class="footer"
    :class="className"
  >
    <div class="footer__top">
      <ul class="footer__list">
        <li>
          <p>Сотрудничество</p>
          <a
            href="mailto:ligatatarstan@gmail.com"
            target="_blank"
            itemprop="email"
            aria-label="Написать письмо на оффициальную почту Liga Studentov"
          >
            ligatatarstan@gmail.com
          </a>
        </li>
        <li>
          <p>Пресс-служба</p>
          <a
            href="mailto:ligastud.pressa@gmail.com"
            target="_blank"
            itemprop="email"
            aria-label="Написать письмо на оффициальную почту пресс службы Liga Studentov"
          >
            ligastud.pressa@gmail.com
          </a>
        </li>
        <li>
          <p>Техническая поддержка</p>
          <a
            href="https://t.me/paulish"
            target="_blank"
            itemprop="employee"
            aria-label="Написать письмо в техническую поддержку Liga Studentov"
          >
            @paulish
          </a>
        </li>
      </ul>
    </div>
    <div class="footer__bottom">
      <div class="footer__bottom-list">
        <div class="footer__bottom-copyright">
          <div class="footer__bottom-docs">
            <b>Службы помощи по конкурсам:</b>
            <a v-for="item in footerHelp" :href="item.link" target="_blank">
              {{ item.title }}
            </a>
          </div>
          <div class="footer__logo">
            <img
              src="~@/assets/footer-logo.svg"
              alt="Логотип Лиги студентов Республики Татарстан региональной молодежной общественной организации, крупнейшего студенческого объединения Поволжья, основанной с 1996 года"
            />
          </div>
          <div
            class="footer__license"
            itemscope
            itemtype="http://schema.org/WPFooter"
          >
            <p>
              Copyright © 2020–{{ currentYear }} РМОО Лига студентов Республики
              Татарстан, Казань — Все права защищены.
            </p>
            <meta itemprop="copyrightHolder" content="РМОО «Лига Студентов»" />
            <meta itemprop="copyrightYear" content="2022" />
          </div>
        </div>
        <div class="footer__bottom-middle">
          <div class="footer__bottom-docs">
            <b>Полезная информация:</b>
            <a v-for="item in footerInfo" :href="item.link" target="_blank">
              {{ item.title }}
            </a>
          </div>
        </div>
        <div class="footer__bottom-right">
          <div class="footer__bottom-docs">
            <b>Документация:</b>
            <router-link to="/requisites" target="_blank">
              Контактные данные и реквизиты
            </router-link>
            <router-link to="/terms" target="_blank">
              Пользовательское соглашение
            </router-link>
            <router-link to="/privacy" target="_blank">
              Соглашение на обработку персональных данных
            </router-link>
            <router-link to="/policy" target="_blank">
              Политика конфиденциальности
            </router-link>
          </div>
          <div class="footer__bottom-socials">
            <a href="https://t.me/liga_stud" target="_blank">
              <Icon type="telegram" />
            </a>
            <a href="https://vk.com/liga_studentov" target="_blank">
              <Icon type="vk" />
            </a>
            <a href="https://t.me/ligastudentovbot" target="_blank">
              <Icon type="tg-bot" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from "@/components/Blocks/Icon.vue";
import footerHelp from "@/enums/footerHelp";
import footerInfo from "@/enums/footerInfo";

export default {
  name: "Footer",
  components: { Icon },
  data() {
    return {
      footerHelp,
      footerInfo,
    };
  },
  props: {
    secondary: Boolean,
  },
  computed: {
    className() {
      return {
        "footer--secondary": this.secondary,
      };
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
.footer {
  background-color: var(--text--color);
  padding: 95px 0 65px 0;
  a {
    color: var(--white);
    &:hover {
      -webkit-text-stroke-width: 0.5px;
    }
  }
  &__top {
    padding: 0 40px 45px 40px;
    border-bottom: 1px solid var(--input--border);
    margin-bottom: 65px;
  }
  ul.footer__list {
    display: grid;
    max-width: 1100px;
    margin: 0 auto;
    grid-template-columns: 40% 35% 25%;
    li {
      color: var(--white);
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem;
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--input--border);
      }
    }
  }
  &__bottom {
    padding: 0 40px;
    &-list {
      display: grid;
      grid-template-columns: 40% 35% 25%;
      max-width: 1100px;
      align-items: start;
      margin: 0 auto;
    }
    &-docs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 14px;
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.9rem;
      a {
        display: inline-block;
        text-decoration: underline;
        color: var(--banner--color);
      }
      b {
        text-decoration: underline;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
    }
    &-socials {
      color: #fff;
      display: flex;
      grid-gap: 15px;
      i {
        font-size: 3.2rem;
        &:hover {
          -webkit-text-stroke-width: 0.05rem;
        }
      }
    }
    &-copyright {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
    }
  }
  &__logo {
    margin-bottom: 20px;
  }
  &__license {
    p {
      text-align: left;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;
      max-width: 280px;
      color: var(--input--placeholder);
    }
  }
  @include adaptive(tablet-big) {
    padding: 65px 0 65px 0;
    ul.footer__list {
      grid-gap: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .footer__bottom {
      &-list {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        text-align: center;
        align-items: center;
      }
      &-docs {
        align-items: unset;
      }
      &-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          text-align: center;
        }
      }
    }
  }
  @include adaptive(phone) {
    padding: 50px 0;
    ul.footer__list {
      li {
        font-size: 1.6rem;
        line-height: 1.9rem;
        p {
          font-size: 1.4rem;
          line-height: 1.7rem;
        }
      }
    }
    &__logo {
      width: 72px;
      height: 33px;
    }
    &__license {
      p {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }
  }
}
</style>
