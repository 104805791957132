<template>
  <div class="cookie-popup">
    <div class="cookie-popup__content">
      <p>
        Продолжая использовать наш сайт, вы даете согласие на
        <a href="https://ligastudentov.com/privacy" target="_blank">
          обработку файлов cookie,
        </a>
        &nbsp;которые обеспечивают правильную работу сайта.
      </p>
      <div class="cookie-popup__btns">
        <Button large @click="agree">Ок</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "PopupCookie",
  components: { Button },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupCookie",
        isShown: false,
      });
    },
    agree() {
      this.$store.commit("isCookieAgreeTrue");
      this.closePopup();
    },
  },
};
</script>

<style lang="scss">
.cookie-popup {
  &__wrapper {
    &.popup-wrapper {
      justify-content: flex-end;
    }
    div.modal-box {
      max-width: 650px;
      padding: 15px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    a {
      text-decoration: underline;
    }
  }
  @include adaptive(phone) {
    &__content {
      flex-wrap: wrap;
      justify-content: center;
      p {
        text-align: center;
      }
    }
  }
}
</style>
